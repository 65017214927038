.button.download__link {
  color: #18191b;
  background: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button.download__link:hover,
.button.download__link:focus {
  background-color: var(--light-orange);
}

.button.download__link svg {
  fill: #18191b;
}

@media screen and (min-width: 768px) {
  .button.download__link {
    background-color: var(--beige);
  }
  .download__link > span {
    display: none;
  }
}
