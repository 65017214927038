/*
 * Copyright 2021 EPAM Systems, Inc. (https://www.epam.com/)
 * SPDX-License-Identifier: Apache-2.0
 */

html {
  height: 100vh;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  /* Primary colors */
  --red: #dc5e47;
  --green: #438f56;
  --black: #18191b;

  /* Additional colors */
  --beige: #f4f2ea;
  --gray: #636666;
  --dark-gray: #282a2d;
  --dark-gray2: #46494e;
  --dark-gray3: #858585;
  --dark-gray4: #737982;
  --light: #cbd2d2;
  --white: #fff;
  --light-orange: #e7675191;
  --extreme-black: #000000;

  --opacity: 0.95;

  color: var(--white);
  background-color: var(--extreme-black);

  /* Page layout */
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
}

svg {
  display: inline-block;
  vertical-align: middle;
  color: var(--white);
}

.cursor-grab {
  cursor: grabbing;
}

.cursor-hand {
  cursor: grab;
}
