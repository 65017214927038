.smart_container {
  margin: 0 1rem;
  padding: 1.05rem 1rem 1rem;
  border: none;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  transition: 0.25s all ease;
}

.smart_container__active {
  border: 2px dashed var(--red);
}

.buttons_toolbar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 10px;
}

.buttons_toolbar button {
  background-color: inherit;
}

.button_start_screen {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button_start_screen svg {
  margin-bottom: 0.3rem;
}

@media screen and (min-width: 768px) {
  .smart_container {
    margin: 0;
    background-color: var(--black);
    border: 2px dashed transparent;
  }

  .button_start_screen {
    flex-direction: row;
  }

  .button_start_screen svg {
    margin-right: 5px;
    margin-bottom: 0;
  }

  .buttons_toolbar {
    flex-direction: row;
    width: 100%;
  }
}

@keyframes rotate {
  0% {
    background-position: 0 0, 100% 100%, 0 100%, 100% 0;
  }
  100% {
    background-position: 100% 0, 0 100%, 0 0, 100% 100%;
  }
}
