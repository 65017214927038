.screen {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 0 2rem;
}

.header_text {
  font-weight: 400;
  font-size: 2rem;
  line-height: 36.31px;
  color: var(--white);
}

.subheader_text {
  display: none;
}
.subheader svg {
  display: none;
}

.container {
  width: 400px;
}

@media screen and (min-width: 768px) {
  .subheader {
    margin: 0;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }

  .subheader_text {
    display: inline-block;
    margin: 0;
    margin-bottom: 10px;
    opacity: 0.6;
    font-size: 14px;
    line-height: 36.31px;
    font-weight: 400;
    text-align: center;
    color: var(--white);
  }
  .subheader svg {
    display: inline-block;
  }

  .github_link {
    margin-right: 10px;
  }

  .container {
    width: 600px;
    margin-top: 30px;
  }
}
