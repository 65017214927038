.spinner {
  position: absolute;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loader,
.loader:after {
  margin: 0 5px;
  padding: 12px;
  border-radius: 50%;
  width: 5em;
  height: 5em;
}
.loader {
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.6em solid rgba(230, 107, 107, 0.4);
  border-right: 0.6em solid rgba(230, 107, 107, 0.4);
  border-bottom: 0.6em solid rgba(230, 107, 107, 0.4);
  border-left: 0.6em solid var(--red);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
  z-index: 2001;
}
.progress {
  z-index: 2001;
  color: white;
  font-size: 26px;
  margin-top: -57px;
}
.text {
  z-index: 2001;
  color: white;
  font-size: 22px;
  margin-top: 40px;
}

.progress_overlay {
  content: '';
  width: 200px;
  height: 200px;
  z-index: 2000;
  background-color: black;
  border-radius: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0.85;
  transform: translate(-50%, -50%);
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: var(--extreme-black);
  opacity: 0.4;
  top: 0;
  left: 0;
  z-index: 1003;
}
