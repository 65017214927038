/*
 * Copyright 2021 EPAM Systems, Inc. (https://www.epam.com/)
 * SPDX-License-Identifier: Apache-2.0
 */

.progress {
  position: absolute;
  top: 5px;
  left: 6px;
  right: 6px;
  height: 20px;
  display: flex;
  align-items: stretch;
  background-color: var(--gray);
  overflow: hidden;
  border-radius: 100px;
  z-index: 1001;
  padding: 2px 2px;
}

.progressBar {
  background-color: var(--red);
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
  background-size: 80px 80px;
  animation: progressAnimation 2s linear infinite;
  border-radius: 100px;
}

.label {
  position: absolute;
  top: 4px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.8rem;
  letter-spacing: 0.5px;
  color: var(--white);
  z-index: 1001;
}

@keyframes progressAnimation {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 80px 0;
  }
}
