.files_container {
  display: flex;
  flex-direction: column;
  margin: 10px 0 0 0;
  background-color: var(--extreme-black);
  font-size: 14px;
  width: 100%;
  padding: 15px;
  border-radius: 6px;
  box-sizing: border-box;
}

.header {
  display: flex;
  margin-bottom: 10px;
  padding-right: 0.5rem;
  flex-basis: 100%;
}

.files {
  display: flex;
}

.left {
  color: var(--dark-gray4);
  margin-right: 2%;
  flex-basis: 40%;
  overflow: hidden;
}
.right {
  flex-basis: 58%;
  overflow: hidden;
}

.element {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.element:not(:first-child) {
  margin-top: 10px;
}

@media screen and (min-width: 768px) {
  .files_container {
    display: flex;
    align-items: normal;
    justify-content: flex-start;
    margin: 10px 0 1.1rem 0;
    background-color: var(--extreme-black);
    font-size: 14px;
    width: 100%;
    padding: 15px;
    border-radius: 6px;
    box-sizing: border-box;
  }

  .element:not(:first-child) {
    margin-top: 10px;
  }
}

@media screen and (min-width: 1024px) {
  .files_container {
    margin: 0 0 1rem 0;
    display: flex;
    justify-content: normal;
  }
}
