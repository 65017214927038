/*!
* Copyright 2021 EPAM Systems, Inc. (https://www.epam.com/)
* SPDX-License-Identifier: Apache-2.0
*/

.wrapperStyles {
  width: 100%;
  height: 100%;
}

.canvasStyles {
  display: block;
  width: 100%;
  height: 100%;
}
