/*!
 * Copyright 2021 EPAM Systems, Inc. (https://www.epam.com/)
 * SPDX-License-Identifier: Apache-2.0
 */

.wrapper {
  display: inline-block;
  color: #18191b;
}

.tooltip {
  display: flex;
  z-index: 1000;
  border-radius: 5px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.14);
  padding: 10px 15px;
  background-color: var(--dark-gray2);
  opacity: 0.98;
}

.body {
  font-size: 16px;
  line-height: 20px;
  color: var(--white);
  white-space: pre;
}

.arrow,
.arrow:before {
  position: absolute;
  background: var(--dark-gray2);
  width: 15px;
  height: 15px;
}

.arrow {
  visibility: hidden;
}

.arrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}

.tooltip[data-popper-placement^='top'] > .arrow {
  bottom: -6px;
  display: none;
}

.tooltip[data-popper-placement^='bottom'] > .arrow {
  top: -6px;
  display: none;
}

.tooltip[data-popper-placement^='left'] > .arrow {
  right: -6px;
  display: none;
}

.tooltip[data-popper-placement^='right'] > .arrow {
  left: -6px;
  display: none;
}

@media screen and (min-width: 768px) {
  .demo__link {
    display: block;
  }

  .tooltip[data-popper-placement^='top'] > .arrow {
    bottom: -6px;
    display: none;
  }

  .tooltip[data-popper-placement^='bottom'] > .arrow {
    top: -6px;
    display: none;
  }

  .tooltip[data-popper-placement^='left'] > .arrow {
    right: -6px;
    display: none;
  }

  .tooltip[data-popper-placement^='right'] > .arrow {
    left: -6px;
    display: none;
  }
}
